<template>
  <div>
    <jor_18bar />

    <v-container fluid>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="กำหนดการ สถานศึกษารายงาน สาขาวิชาสำหรับตำแหน่งว่าง"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <PeriodCollegeManpowers_req />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PeriodCollegeManpowers_req from "../../components/admin/periodCollegeManpowers_req.vue";
import jor_18bar from "../../components/admin/jor_18bar";

export default {
  name: "sys_config_collegemanpower",
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
  computed: {},
  components: { PeriodCollegeManpowers_req,jor_18bar }
};
</script>
